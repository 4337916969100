import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import "./Category.css";
import Search from "../assets/Vector.png";
import { useNavigate } from 'react-router-dom';
import AddCategory from '../components/AddCategory';
import EditCategory from '../components/EditCategory';

Modal.setAppElement('#root');

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    backgroundColor: '#D9D9D9',
    textAlign: 'center'
  }
};

function Category() {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newsText, setNewsText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingNews, setLoadingNews] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  console.log("ACCESS TOKEN IN CATEGORY", accessToken);
  const [image, setImage] = useState(null);
  const [newsHeading, setNewsHeading] = useState('');
  const [password,setpassWord] = useState("")

  const handleOpenEditModal = (category) => {
    setSelectedCategory(category);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedCategory(null);
  };

  const handleOpenNewsModal = () => {
    setIsNewsModalOpen(true);
  };

  const handleCloseNewsModal = () => {
    setIsNewsModalOpen(false);
    setNewsText('');
  };

  const fetchCategories = useCallback(async () => {
    console.log("TOKEN", accessToken);
    setLoading(true);
    try {
      const response = await axios.get('https://service.purposefin.app/content/fetch/categories-list', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log("CATEGORIES", response.data.categories);
      setCategories(response.data.categories);
    } catch (error) {
      setError('There was an error fetching the categories.');
      console.error('There was an error fetching the categories:', error);
    }
    setLoading(false);
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      fetchCategories();
    } else {
      console.error('No access token found');
    }
  }, [accessToken, fetchCategories]);

  console.log("CAT IN STATE", categories);

  const handleCategoryClick = (categoryId, title) => {
    navigate(`/course/${categoryId}`, { state: { title } });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (id, title) => {
    console.log("Deleted category is", title);
    const confirmDelete = window.confirm(`Are you sure you want to delete the category "${title}"?`);
    if (confirmDelete) {
      setLoadingDelete(true);
      try {
        await axios.delete(`https://service.purposefin.app/content/delete/category/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
        setError('There was an error deleting the category.');
      }
      setLoadingDelete(false);
    }
  };

  // const handleNewsSubmit = async () => {
  //   const payload = {
  //     password: "News+Password+321+Purpose",
  //     newsText
  //   };

  //   setLoadingNews(true);
  //   console.log(payload,"news");
  //   try {
  //     const response = await axios.post('https://service.purposefin.app/content/upload/news', payload, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     if (response.status === 200) {
  //       console.log("News uploaded successfully:", response.data);
  //       handleCloseNewsModal();
  //     }
  //   } catch (error) {
  //     console.error('Error uploading news:', error);
  //   }
  //   setLoadingNews(false);
  // };
  // const handleNewsSubmit = async () => {

  //   const formData = new FormData();
  //   formData.append('image', image);
  
  //   const metadata = {
  //     password,
  //     newsText,
  //     newsHeading
  //   };
  //   formData.append('metadata', JSON.stringify(metadata));

  //   setLoadingNews(true);
  //   console.log(formData, "news");
  //   try {
  //     const response = await axios.post('https://service.purposefin.app/content/create/news', formData, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
  //     console.log("NEWS",response.data);
  //     if (response.status === 200) {
  //       console.log("News uploaded successfully:", response.data);
  //       handleCloseNewsModal();
  //     }
  //   } catch (error) {
  //     console.error('Error uploading news:', error);
  //   }
  //   setLoadingNews(false);
  // };
  //Divyesh@9597
  const handleNewsSubmit = async () => {
    const formData = new FormData();
    formData.append('image', image);
  
    const metadata = {
      password: "Divyesh@9597",
      newsText,
      newsHeading
    };
    formData.append('metadata', JSON.stringify(metadata));
  
    setLoadingNews(true);
    console.log(formData, "news");
    try {
      const response = await axios.post('https://service.purposefin.app/content/create/news', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("NEWS", response.data);
      if (response.status === 200) {
        console.log("News uploaded successfully:", response.data);
        handleCloseNewsModal();
      }
    } catch (error) {
      console.error('Error uploading news:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      }
    }
    setLoadingNews(false);
  };

  const handleLogout = async () => {
    console.log("login token",accessToken);
    try {
      const response = await axios.post('https://service.purposefin.app/user/account/logout', {},{
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log("logout",response.data);
      if (response.status === 200) {
        localStorage.removeItem('accessToken');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  


  return (
    <div className='category'>
      <div className="category-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h5 style={{ textAlign: 'left' }}>Category catalog</h5>
          <div style={{ position: 'relative' }}>
            <input type='text' placeholder='Search category' style={{ border: '1px solid #E2E8F0', height: 25, borderRadius: 5, paddingLeft: 50, color: 'black', fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold' }} />
            <div style={{ position: 'absolute', top: 20, left: 10 }}>
              <img src={Search} alt='' />
            </div>
            <button onClick={handleOpenModal} style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add New</button>
            <button onClick={handleOpenNewsModal} style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Upload News</button>
            <button onClick={handleLogout} style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Log out</button>

          </div>
        </div>
        <AddCategory
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          fetchChapters={fetchCategories}
        />
        {selectedCategory && (
          <EditCategory
            isOpen={isEditModalOpen}
            onRequestClose={handleCloseEditModal}
            fetchChapters={fetchCategories}
            category={selectedCategory}
          />
        )}
        <Modal
          isOpen={isNewsModalOpen}
          onRequestClose={handleCloseNewsModal}
          contentLabel="Upload News"
          style={customModalStyles}
        >
          <form onSubmit={(e) => { e.preventDefault(); handleNewsSubmit(); }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <h2>Upload News Content</h2>
              <label htmlFor="newsHeading">News Heading</label>
              <input
                type="text"
                id="newsHeading"
                value={newsHeading}
                onChange={(e) => setNewsHeading(e.target.value)}
                required
                style={{ width: '100%', marginBottom: 10 }}
              />
               <label htmlFor="newsHeading">Password</label>
               <input
                type="text"
                id="password"
                value={password}
                onChange={(e) => setpassWord(e.target.value)}
                required
                style={{ width: '100%', marginBottom: 10 }}
              />
              <label htmlFor="newsText">News Text</label>
              <textarea
                id="newsText"
                value={newsText}
                onChange={(e) => setNewsText(e.target.value)}
                required
                style={{ width: '100%', height: '100px' }}
              />
              <label htmlFor="newsImage">News Image</label>
              <input
                type="file"
                id="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                style={{ marginTop: 10 }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <button type="submit" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>
                {loadingNews ? 'Loading...' : 'Submit'}
              </button>
              <button type="button" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }} onClick={handleCloseNewsModal}>Cancel</button>
            </div>
          </form>
        </Modal>
        {error && <p className="error">{error}</p>}
        {loading ? (
          <p>Loading categories...</p>
        ) : (
          <table className="category-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Category Name</th>
                <th>Category Id</th>
                <th>Category Tags</th>
                <th>Actions</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={index}>
                  <td>{category.sequence}</td>
                  <td onClick={() => handleCategoryClick(category.categoryId, category.title)}>{category.title}</td>
                  <td>{category.categoryId}</td>
                  <td>{category.tags.join(', ')}</td>
                  <td style={{ cursor: 'pointer' }} onClick={() => handleOpenEditModal(category)}>Edit</td>
                  <td style={{ cursor: 'pointer' }} onClick={() => handleDelete(category.categoryId, category.title)}>
                    {loadingDelete ? 'Deleting...' : 'Delete'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Category;
