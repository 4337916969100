import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams ,useLocation} from 'react-router-dom';
import "./Category.css";
import Search from "../assets/Vector.png";
import EditContentModal from '../components/EditContentModal';

function Contents() {
  const { chapterId } = useParams();
  const [contents, setContents] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  const { title } = location.state || {};
 

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken) {
      fetchContents();
    } else {
      console.error('No access token found');
    }
  }, []);

  const fetchContents = async () => {
    try {
      const response = await axios.get(`https://service.purposefin.app/content/fetch/contents-list/${chapterId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setContents(response.data.data);
      setLoading(false);
      console.log("CONTENT",response.data.data);
    } catch (error) {
      setError('There was an error fetching the contents.');
      setLoading(false);
    }
  };

  const renderContentBody = (body) => {
    if (Array.isArray(body) && body.length > 0) {
      return body.map((para, index) => (
        <p key={index}>{para}</p>
      ));
    }
    return null;
  };

  const renderContent = (content) => {
    if (content.contentType === 'video') {
      return (
        <video width="100%" controls>
          <source src={content.s3Key} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return renderContentBody(content.body);
  };
 

  const handleAddContent = (chapterId) => {
    navigate(`/add-content/${chapterId}`); // Navigate to AddContentForm
  };
  const handleEditContent = (content) => {
    setSelectedContent(content);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setSelectedContent(null);
  };

  const handleDeleteContent = async (contentId, title) => {
    console.log("Deleted content is", title);
    const confirmDelete = window.confirm(`Are you sure you want to delete the content "${title}"?`);
    if (confirmDelete) {
      try {
        await axios.delete(`https://service.purposefin.app/content/delete/content/${contentId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        fetchContents();
      } catch (error) {
        console.error('Error deleting content:', error);
        setError('There was an error deleting the content.');
      }
    }
  };

  const handleAddAssignment = () => {
    navigate(`/add-assignment/${chapterId}`);
  }



  return (
    <div className='category'>
      <div className="category-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h5 style={{ textAlign: 'left' }}>Content List</h5>
          <h5 style={{ textAlign: 'left' }}>{title && title}</h5>
          <div style={{ position: 'relative' }}>
            <input type='text' placeholder='Search content' style={{ border: '1px solid #E2E8F0', height: 25, borderRadius: 5, paddingLeft: 50, color: 'black', fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold' }} />
            <div style={{ position: 'absolute', top: 20, left: 10 }}>
              <img src={Search} alt='' />
            </div>
            <button  onClick={() => handleAddContent(chapterId)}  style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add New</button>
            <button  onClick={()=> handleAddAssignment(chapterId)}  style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add Assignment</button>

          </div>
        </div>
        {error && <p className="error">{error}</p>}
        <p>{loading}</p>
        <table className="category-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Content Name</th>
              <th>Content Id</th>
              <th>Type</th>
              <th>  </th>
              <th>Actions</th>
              <th>  </th>
            </tr>
          </thead>
          {loading ? (
            <p>Loading</p>
          ) : (
            <tbody>
              {contents?.map((content, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{content.title}</td>
                  <td>{content.contentId}</td>
                  <td>{content.type}</td>
                  {content.contentType === 'video' ? (
      <td>
        <video width="20%" controls>
          <source src={content.s3Key} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </td>
    ) : content.contentType === 'image' ? (
      <td>
        <img src={content.s3Key} alt={content.title} width="20%" />
      </td>
    ) : (
      <td>
        {content.body.map((body, idx) => (
          <div key={idx}>
            {Object.keys(body).map((key) => (
              <p key={key}>{body[key]}</p>
            ))}
          </div>
        ))}
      </td>
    )}
                  <td style={{ cursor: 'pointer' }} onClick={() => handleEditContent(content)}>Edit</td>
                  <td style={{ cursor: 'pointer' }} onClick={() => handleDeleteContent(content.contentId,content.title)} >Delete</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        
      </div>
      {selectedContent &&
      <EditContentModal
        isOpen={isEditModalOpen}
        onRequestClose={closeModal}
        content={selectedContent}
        fetchContents={fetchContents}
      />}
    </div>
  );
}

export default Contents;
