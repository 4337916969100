import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import "./Category.css"
import Search from "../assets/Vector.png"
import AddChapterModal from '../components/AddChapterModal';
import EditChapterModal from '../components/EditChapterModal';

function Chapters() {
    const { courseId } = useParams();
    const [chapters, setChapters] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { title } = location.state || {};

    console.log(courseId);

    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        if (accessToken) {
            fetchCategories();
        } else {
            console.error('No access token found');
        }
    }, []);
    const handleOpenEditModal = (chapter) => {
        setSelectedChapter(chapter);
        setIsEditModalOpen(true);
      };
    
      const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedChapter(null);
      };
    

    const fetchCategories = async () => {
        console.log("TOKEN", accessToken);
        try {
            const response = await axios.get(`https://service.purposefin.app/content/fetch/chapters-list/${courseId}`
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            console.log("CHAPTERS", response.data.data);
            setChapters(response.data.data);
            setLoading(false)
        } catch (error) {
            setError('There was an error fetching the categories.');
            console.error('There was an error fetching the categories:', error);
            setLoading(false);
        }
    };
    console.log("CHAPTER IN STATE", chapters);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    const handleDelete = async(id,title) => {
        console.log("Deleted course is", title);
        const confirmDelete = window.confirm(`Are you sure you want to delete the chapter "${title}"?`);
        if (confirmDelete) {
          try {
          const res =  await axios.delete(`https://service.purposefin.app/content/delete/chapter/${id}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
            console.log("DEL",res);
            fetchCategories();
          } catch (error) {
            console.error('Error deleting chapter:', error);
            setError('There was an error deleting the chapter.');
          }
        }
    }

    const handleContent = (chapterId,title) => {
        navigate(`/contents/${chapterId}`,{ state: { title } });
    }
    const handleSendNotification = () => {
        //
    }

    return (
        <div className='category'>
            <div className="category-container">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 style={{ textAlign: 'left' }}>Chapter Catalog</h5>
                    <h5 style={{ textAlign: 'left' }}>{title && title}</h5>
                    <div style={{ position: 'relative' }}>
                        <input type='text' placeholder='Search chapter' style={{ border: '1px solid #E2E8F0', height: 25, borderRadius: 5, paddingLeft: 50, color: 'black', fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold' }} />
                        <div style={{ position: 'absolute', top: 20, left: 10 }}>
                            <img src={Search} alt='' />
                        </div>
                        <button onClick={handleOpenModal}  style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add New</button>
                    </div>
                </div>
                <AddChapterModal
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    fetchCategories={fetchCategories}
                    courseId={courseId}
                />
                 {selectedChapter && (
          <EditChapterModal
            isOpen={isEditModalOpen}
            onRequestClose={handleCloseEditModal}
            fetchChapters={fetchCategories}
            chapter={selectedChapter}
          />
        )}
                {error && <p className="error">{error}</p>}
                <p>{loading}</p>
                <table className="category-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Chapter Name</th>
                            <th>Chapter Id</th>
                            <th>Actions</th>
                            <th>  </th>
                            <th> </th>
                        </tr>
                    </thead>
                    {loading ? (
                        <p>Loading</p>
                    ) : (
                        <tbody>
                            {chapters?.map((chapter, index) => (
                                <tr key={index}>
                                    <td>{chapter.sequence}</td>
                                    <td onClick={()=> handleContent(chapter.chapterId,chapter.title)}>{chapter.title}</td>
                                    <td>{chapter.chapterId}</td>
                                    <td style={{ cursor: 'pointer' }} onClick={() => handleOpenEditModal(chapter)}>Edit</td>
                                    <td style={{ cursor: 'pointer' }} onClick={()=> handleDelete(chapter.chapterId,chapter.title)}>Delete</td>
                                    <button style={{ backgroundColor: '#553C9A', height: 30, marginLeft: 10,marginTop:10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 130, borderRadius: 5 }} onClick={()=> handleSendNotification(chapter.chapterId,chapter.title)}>Send Notification</button>
                                </tr>
                            ))}
                        </tbody>
                    )}

                </table>
            </div>
        </div>
    )
}

export default Chapters
