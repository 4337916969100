import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
   // height: '50%',
    backgroundColor: '#D9D9D9',
    textAlign: 'center'
  }
};

const EditCourse = ({ isOpen, onRequestClose, fetchCategories, categoryId, course, fetchChapters }) => {
  console.log("COURSE", course);
  const [title, setTitle] = useState('');
  const [sequence, setSequence] = useState('');
  const [durationInMinutes, setDurationInMinutes] = useState('');
  const [description, setDescription] = useState('');
  const [difficultyLevel, setDifficultyLevel] = useState('');
  const [totalChapters, setTotalChapters] = useState('');
  const [tags, setTags] = useState('');
  const [image, setImage] = useState(null);
  const [loading,setLoading] = useState(false);
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (course) {
      setTitle(course.title);
      setDescription(course.description);
      setDifficultyLevel(course.difficultyLevel);
      setDurationInMinutes(course.durationInMinutes);
      setTotalChapters(course.totalChapters);
      setSequence(course.sequence);
      setTags(course.tags ? course.tags.join(', ') : '');
    }
  }, [course]);

  const handleUpdateCourse = async () => {
    const tagsArray = tags.split(',').map(tag => tag.trim());
    const payload = {
      title,
      description,
      tags: tagsArray,
      difficultyLevel,
      durationInMinutes,
      totalChapters,
      sequence
    };
    console.log(payload);
    setLoading(true);
    try {
      const response = await axios.put(`https://service.purposefin.app/content/update/course/${course.courseId}`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      console.log("RESPONSE COURSE EDIT", response);
      if (response.status === 200) {
        fetchChapters();
        onRequestClose();
      }
    } catch (error) {
      console.error('Error updating course:', error);
    }
    setLoading(false);
  };

  const handleUpdateImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (image) {
      formData.append('image', image);
    }
    setLoading(true)
    try {
      const response = await axios.put(`https://service.purposefin.app/content/update/course-thumbnail/${course.courseId}`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("RESPONSE IMAGE UPDATE", response);
      if (response.status === 200) {
        fetchChapters();
        onRequestClose();
      }
    } catch (error) {
      console.error('Error updating course image:', error);
    }
    setLoading(false)
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Course"
      style={customStyles}
    >
      <h2>Edit Course</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={(e) => { e.preventDefault(); handleUpdateCourse(); }}>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="duration">Duration</label>
          <input
            type="text"
            id="duration"
            value={durationInMinutes}
            onChange={(e) => setDurationInMinutes(e.target.value)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="tags">Tags</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="totalChapters">Total Chapters</label>
          <input
            type="text"
            id="totalChapters"
            value={totalChapters}
            onChange={(e) => setTotalChapters(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="sequence">Sequence</label>
          <input
            type="number"
            id="sequence"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="difficultyLevel">Difficulty Level</label>
          <input
            type="number"
            id="difficultyLevel"
            value={difficultyLevel}
            onChange={(e) => setDifficultyLevel(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <button type="submit" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Update Course</button>
          <button type="button" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }} onClick={onRequestClose}>Cancel</button>
        </div>
      </form>
        <form onSubmit={handleUpdateImage} style={{height:400}}>
          <div>
          <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
            <button type="submit" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 150, borderRadius: 5 }}>Update Thumbnail</button>
          </div>
        </form>
    </Modal>
  );
};

export default EditCourse;
