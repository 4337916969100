import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '50%',
      backgroundColor:'#D9D9D9',
      textAlign:'center'
    }
  };

const AddCategory = ({ isOpen, onRequestClose, fetchChapters }) => {
  const [title, setTitle] = useState('');
  const [sequence, setSequence] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const [tags, setTags] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddChapter = async () => {
    const tagsArray = tags.split(',').map(tag => tag.trim());
    setLoading(true);
    try {
      const response = await axios.post('https://service.purposefin.app/content/create/category', {
        title,
        sequence,
        tags: tagsArray,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (response.status === 201) {
        // Fetch the updated chapters list after adding the new chapter
        fetchChapters();
        // Close the modal
        onRequestClose();
      }
    } catch (error) {
      console.error('Error adding chapter:', error);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add New Chapter"
      style={customStyles}
    >
      <h2>Add New Categgory</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={(e) => { e.preventDefault(); handleAddChapter(); }}>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
         <div><label htmlFor="sequence">Sequence</label></div>
          <input
            type="number"
            id="sequence"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="tags">Tags</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <div style={{marginTop:20}}>
        <button type="submit"  style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add Chapter</button>
        <button type="button" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }} onClick={onRequestClose}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCategory;
