// src/components/AddImageContent.js
import axios from 'axios';
import React, { useState } from 'react';

const AddImageContent = ({ chapterId, onSuccess, onError }) => {
  const [image, setImageFile] = useState(null);
  const [sequence, setSequence] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const [loading,setloading] = useState(false);

  const handleImageFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSequenceChange = (e) => {
    setSequence(parseFloat(e.target.value));
  };

  const handleSubmitImageContent = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const metadata = {
        sequence
      };
  
      formData.append('metadata', JSON.stringify(metadata));
      if (image) {
        formData.append('image', image);
      }
setloading(true);
    try {
      const response = await axios.post(
        `https://service.purposefin.app/content/create/content-image/${chapterId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('IMAGE RESPONSE ADDED', response.data);
      setSuccess('Image content added successfully');
      setError('');
      onSuccess(); // Call the onSuccess callback to handle success
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('There was an error uploading the image.');
      onError(); // Call the onError callback to handle errors
    }
    setloading(false)
  };

  return (
    <div className="add-image-content">
      <h2>Upload Image Content</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={handleSubmitImageContent} className="content-form">
        <div className="form-group">
          <label htmlFor="image">Image File:</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageFileChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="imageSequence">Sequence:</label>
          <input
            type="number"
            step="0.1"
            id="imageSequence"
            value={sequence}
            onChange={handleSequenceChange}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Upload Image</button>
      </form>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
};

export default AddImageContent;
