import axios from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './AddContentForm.css';
import AddImageContent from '../components/AddImageContent';

const AddContentForm = () => {
  const { chapterId } = useParams(); // Get the chapter ID from URL parameters
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(''); // Single textarea for all content
  const [sequence, setSequence] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [video, setVideoFile] = useState(null);
  const [durationInSeconds, setDurationInSeconds] = useState('');
  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem('accessToken');

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeContent = (e) => {
    setContent(e.target.value);
  };

  const handleChangeSequence = (e) => {
    setSequence(parseFloat(e.target.value));
  };

  const handleVideoFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleDurationChange = (e) => {
    setDurationInSeconds(e.target.value);
  };

 
  
  // const parseContent = () => {
  //   const contentBlocks = [];
  //   let currentBlock = null;
  
  //   const lines = content.split('\n');
  
  //   // Regex pattern to detect headings
  //  // const headingPattern = /^([\w\s]+):/;
  //  const headingPattern = /^([\w\s*&%@_+-]+):/;

  //   lines.forEach(line => {
  //     if (headingPattern.test(line)) {
  //       // If there's an existing block, push it to contentBlocks
  //       if (currentBlock) {
  //         contentBlocks.push(currentBlock);
  //       }
  
  //       // Start a new block with the heading
  //       currentBlock = {
  //         heading: line.match(headingPattern)[1].trim(),
  //         paragraphs: []
  //       };
  //     } else if (line.trim()) {
  //       // If the line is not a heading, treat it as a paragraph
  //       if (!currentBlock) {
  //         currentBlock = { paragraphs: [] };
  //       }
  //       currentBlock.paragraphs.push(line.trim());
  //     }
  //   });
  
  //   // Push the last block if it exists
  //   if (currentBlock) {
  //     contentBlocks.push(currentBlock);
  //   }
  
  //   return contentBlocks;
  // };
  
  const parseContent = () => {
    const contentBlocks = [];
    let currentBlock = null;
  
    const lines = content.split('\n').filter(line => line.trim() !== ''); // Remove empty lines
  
    // Regex pattern to detect headings (line with ":")
    const headingPattern = /^([\w\s*&%@_+-]+):\s*(.*)$/;
  
    lines.forEach(line => {
      const match = line.match(headingPattern);
  
      if (match) {
        // If there's an existing block, push it to contentBlocks
        if (currentBlock) {
          contentBlocks.push(currentBlock);
        }
  
        // Start a new block with the heading
        currentBlock = {
          heading: match[1].trim(), // Treat text before ":" as heading
          paragraphs: [],
        };
  
        // If there's content after the colon, treat it as the first paragraph
        if (match[2].trim()) {
          currentBlock.paragraphs.push(match[2].trim());
        }
      } else {
        // No match, treat the line as a normal paragraph
        if (!currentBlock) {
          // If no current heading block, create a new paragraph-only block
          currentBlock = {
            heading: null, // No heading for paragraph-only blocks
            paragraphs: [],
          };
        }
  
        // Add the line as a paragraph
        currentBlock.paragraphs.push(line.trim());
      }
    });
  
    // Push the last block if it exists
    if (currentBlock) {
      contentBlocks.push(currentBlock);
    }
  
    return contentBlocks;
  };
  
  
  
  const handleSubmitTextContent = async (e) => {
    e.preventDefault();
    
    const contentBlocks = parseContent(); // Parse the content
    
    const body = {};
  
    contentBlocks.forEach((block, index) => {
      if (block.heading) {
        body[`heading${index + 1}`] = block.heading;
      }
      block.paragraphs.forEach((para, paraIndex) => {
        body[`para${index + 1}_${paraIndex + 1}`] = para;
      });
    });
  
    try {
      const response = await axios.post(
        `https://service.purposefin.app/content/create/content-text/${chapterId}`,
        {
          title,
          body,
          sequence
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log("RESPONSE", response.data);
      setSuccess('Text content added successfully');
      setTitle('');
      setContent(''); // Clear content
      setSequence(1);
    } catch (error) {
      console.error('Error adding text content:', error);
      setError('There was an error adding the text content.');
    }
  };
  

  const handleSubmitVideoContent = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!video) {
      setError('Please select a video file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('video', video);

    const metadata = {
      sequence,
      durationInSeconds,
    };
    formData.append('metadata', JSON.stringify(metadata));

    try {
      const response = await axios.post(
        `https://service.purposefin.app/content/create/content-video/${chapterId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log('VIDEO RESPONSE:', response.data);
      setSuccess('Video content added successfully');
      setVideoFile(null);
      setSequence(0);
      setDurationInSeconds(0);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(`Error: ${error.response.data.message || 'Unable to upload video.'}`);
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('Error: No response from server.');
      } else {
        console.error('Error message:', error.message);
        setError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    setSuccess('Content added successfully');
    setError('');
  };

  const handleError = () => {
    setError('There was an error adding the content.');
    setSuccess('');
  };

  return (
    <div className="add-content-form">
      <h2>Add Content to Chapter</h2>
      <form onSubmit={handleSubmitTextContent} className="content-form">
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={handleChangeTitle}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">Content:</label>
          <textarea
            id="content"
            value={content}
            onChange={handleChangeContent}
            rows="10"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="sequence">Sequence:</label>
          <input
            type="number"
            step="0.1"
            id="sequence"
            value={sequence}
            onChange={handleChangeSequence}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Add Text Content</button>
      </form>

      <h2>Upload Video Content</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={handleSubmitVideoContent} className="content-form">
        <div className="form-group">
          <label htmlFor="video">Video File:</label>
          <input
            type="file"
            id="video"
            accept="video/*"
            onChange={handleVideoFileChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="videoSequence">Sequence:</label>
          <input
            type="text"
            id="videoSequence"
            value={sequence}
            onChange={handleChangeSequence}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="videoDuration">Duration (in seconds):</label>
          <input
            type="text"
            id="videoDuration"
            value={durationInSeconds}
            onChange={handleDurationChange}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Upload Video</button>
      </form>

      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}

      <AddImageContent
        chapterId={chapterId}
        onSuccess={handleSuccess}
        onError={handleError}
      />

      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
};

export default AddContentForm;
