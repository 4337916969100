import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './AddAssignment.css';

function AddAssignment() {
  const { chapterId } = useParams();

  const [title, setTitle] = useState('');
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  const [questions, setQuestions] = useState([
    {
      questionText: '',
      options: ['', '', '', ''], // Updated to 4 options
      correctOptionIndex: 0,
    },
  ]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDifficultyChange = (e) => {
    setDifficultyLevel(e.target.value);
  };

  const handleQuestionChange = (index, e) => {
    const newQuestions = [...questions];
    newQuestions[index].questionText = e.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, e) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleCorrectOptionChange = (questionIndex, e) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].correctOptionIndex = parseInt(e.target.value, 10);
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        questionText: '',
        options: ['', '', '', ''], // Updated to 4 options
        correctOptionIndex: 0,
      },
    ]);
  };

  const removeQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://service.purposefin.app/content/create/assignment', {
        chapterId,
        title,
        difficultyLevel: parseInt(difficultyLevel, 10),
        questions,
      });

      console.log('Response:', response.data);
      setSuccess('Assignment created successfully!');
      setError('');
    } catch (error) {
      console.error('Error creating assignment:', error);
      setSuccess('');
      setError('Failed to create the assignment.');
    }
  };

  return (
    <div className="add-assignment-container">
      <h2 className="form-title">Add Assignment</h2>
      {success && <p className="success-message">{success}</p>}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="assignment-form">
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Difficulty Level:</label>
          <input
            type="number"
            value={difficultyLevel}
            onChange={handleDifficultyChange}
            min="1"
            max="5"
            required
            className="form-control"
          />
        </div>
        {questions.map((question, qIndex) => (
          <div key={qIndex} className="question-group">
            <h4>Question {qIndex + 1}</h4>
            <div className="form-group">
              <label>Question Text:</label>
              <input
                type="text"
                value={question.questionText}
                onChange={(e) => handleQuestionChange(qIndex, e)}
                required
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Options:</label>
              {question.options.map((option, oIndex) => (
                <div key={oIndex} className="form-group option-group">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(qIndex, oIndex, e)}
                    required
                    className="form-control"
                  />
                </div>
              ))}
            </div>
            <div className="form-group">
              <label>Correct Option:</label>
              <select
                value={question.correctOptionIndex}
                onChange={(e) => handleCorrectOptionChange(qIndex, e)}
                className="form-control"
              >
                {question.options.map((_, index) => (
                  <option key={index} value={index}>
                    Option {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <button type="button" className="remove-button" onClick={() => removeQuestion(qIndex)}>
              Remove Question
            </button>
          </div>
        ))}
        <button type="button" className="add-question-button" onClick={addQuestion}>
          Add Another Question
        </button>
        <br />
        <button type="submit" className="submit-button">Submit Assignment</button>
      </form>
    </div>
  );
}

export default AddAssignment;
