import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import "./Category.css"
import Search from "../assets/Vector.png"
import AddCourses from '../components/AddCourses';
import EditCourse from '../components/EditCourse';


function Courses() {
    const { categoryId } = useParams();
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditmodal, setIsEditModal] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const location = useLocation();
    const { title } = location.state || {};

    console.log(title);
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        if (accessToken) {
            fetchCategories();
        } else {
            console.error('No access token found');
        }
    }, []);

    const fetchCategories = async () => {
        console.log("TOKEN", accessToken);
        setLoading(true);
        try {
            const response = await axios.get(`https://service.purposefin.app/content/fetch/courses-list/${categoryId}`
                , {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            console.log("COURSES", response.data.data);
            setCourses(response.data.data);
        } catch (error) {
            setError('There was an error fetching the categories.');
            console.error('There was an error fetching the categories:', error);
        }
        setLoading(false)
    };

    console.log("COURSES IN STATE", courses);

    const handleChapters = (courseId, title) => {
        navigate(`/chapter/${courseId}`, { state: { title } });
    }
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsEditModal(false)
    };

    const handleOpenEditModal = (course) => {
        setIsEditModal(true)
        setSelectedCourse(course)
    }


    const handleDelete = async (id, title) => {
        console.log("Deleted course is", title, id)
        const confirmDelete = window.confirm(`Are you sure you want to delete the course "${title + id}"?`);
        if (confirmDelete) {
            setLoading(true);
            try {
                const res = await axios.delete(`https://service.purposefin.app/content/delete/courses/${id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                console.log("DEL", res.data);
                fetchCategories();
            } catch (error) {
                console.error('Error deleting course:', error);
                setError('There was an error deleting the course.');
            }
            setLoading(false)
        }
    }

    const sendNotification = async (courseId,title) => {
        const payload = {
            title: title,
            // body: "Understanding Your Financial Personality",
            categoryId: categoryId,
            courseId: courseId,
            imageUrl: "https://d1h840n47wtcjd.cloudfront.net/images/3mh9keah3n.png"
        };

        console.log("PAYLOAD", payload);

        try {
            const response = await axios.post('https://service.purposefin.app/notification/send-course-update-push-notification', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Notification sent successfully:', response.data);
            alert(`Notification Sent Successfully: ${title}`)
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };






    return (
        <div className='category'>
            <div className="category-container">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 style={{ textAlign: 'left' }}>Courses Catalog</h5>
                    <h5>{title && title}</h5>
                    {loading && <div className="loader">Loading...</div>}
                    <div style={{ position: 'relative' }}>
                        <input type='text' placeholder='Search course' style={{ border: '1px solid #E2E8F0', height: 25, borderRadius: 5, paddingLeft: 50, color: 'black', fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold' }} />
                        <div style={{ position: 'absolute', top: 20, left: 10 }}>
                            <img src={Search} alt='' />
                        </div>
                        <div style={{ flexDirection: 'column', alignItems: 'center', marginTop: 20, justifyContent: 'center' }}>
                            <button onClick={handleOpenModal} style={{ backgroundColor: '#553C9A', height: 30, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add New</button>
                        </div>
                    </div>
                </div>
                <AddCourses
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    fetchChapters={fetchCategories}
                    categoryId={categoryId}
                />
                {selectedCourse &&
                    <EditCourse
                        isOpen={isEditmodal}
                        onRequestClose={handleCloseModal}
                        fetchChapters={fetchCategories}
                        course={selectedCourse}
                    />
                }


                {error && <p className="error">{error}</p>}
                <table className="category-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Course Name</th>
                            <th>CourseId</th>
                            <th>Course Thumbnail</th>
                            <th>Course tags</th>
                            <th>Actions</th>
                            <th>  </th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map((course, index) => (
                            <tr key={index}>
                                <td>{course.sequence}</td>
                                <td onClick={() => handleChapters(course.courseId, course.title)}>{course.title}</td>
                                <td>{course.courseId}</td>
                                <td><img src={course.s3Key} alt={course.title} style={{ width: '200px', height: '100px' }} /></td>
                                {/* <td>{course.tags.join(', ')}</td> */}
                                <td style={{ cursor: 'pointer' }} onClick={() => handleOpenEditModal(course)} >Edit</td>
                                <td style={{ cursor: 'pointer' }} onClick={() => handleDelete(course.courseId, course.title)}>Delete</td>
                                <button onClick={() => sendNotification(course.courseId,course.title)} style={{ backgroundColor: '#553C9A', height: 30, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 130, borderRadius: 5 ,marginTop:40}}>Send Notification</button>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Courses
