import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '50%',
      backgroundColor:'#D9D9D9',
      textAlign:'center'
    }
  };

const AddCourses = ({ isOpen, onRequestClose, fetchChapters,categoryId }) => {
  console.log("course id",categoryId);
  const [title, setTitle] = useState('');
  const [sequence, setSequence] = useState('');
  const [durationInMinutes,setdurationInMinutes] = useState('')
  const [description,setDescription] = useState ('')
  const [difficultyLevel,setDifficultylevel] = useState('')
  const [totalChapters,setTotalChapters] = useState('')
  const accessToken = localStorage.getItem('accessToken');
  const [image, setImage] = useState(null);
  const [tags, setTags] = useState('');
  const [loading,setLoading] = useState(false)

  console.log("TOKEN",accessToken);

  const handleAddChapter = async () => {
    const tagsArray = tags.split(',').map(tag => tag.trim());
    const formData = new FormData();

    // formData.append('title', title);
    // formData.append('description', description);
    // formData.append('tags',tagsArray);
    // formData.append('difficultyLevel', difficultyLevel);
    // formData.append('durationInMinutes', durationInMinutes);
    // formData.append('totalChapters', totalChapters);
    // formData.append('sequence', sequence);
    //   if (image) {
    //   formData.append('image', image);
    // }
    const metadata = {
      title,
      description,
      tagsArray,
      difficultyLevel,
      durationInMinutes,
      totalChapters,
      sequence
    };

    formData.append('metadata', JSON.stringify(metadata));
    if (image) {
      formData.append('image', image);
    }
setLoading(true);

    try {
      console.log(...formData.entries());
      const response = await axios.post(`https://service.purposefin.app/content/create/course/${categoryId}`,formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      console.log("RESPONSE COURSE ADD",response);
      if (response.status === 201) {
        // Fetch the updated chapters list after adding the new chapter
        fetchChapters();
        // Close the modal
        onRequestClose();
      }
    } catch (error) {
      console.error('Error adding chapter:', error);
    }
    setLoading(false)
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add New Chapter"
      style={customStyles}
    >
      <h2>Add New Course</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={(e) => { e.preventDefault(); handleAddChapter(); }}>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
          <label htmlFor="title">Duration</label>
          <input
            type="text"
            id="duration"
            value={durationInMinutes}
            onChange={(e) => setdurationInMinutes(e.target.value)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="tags">Tags</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder='please use comma seperated values'
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
          <label htmlFor="title">Total Chapters</label>
          <input
            type="text"
            id="Total Chapters"
            value={totalChapters}
            onChange={(e) => setTotalChapters(e.target.value)}
            required
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
         <div><label htmlFor="sequence">Sequence</label></div>
          <input
            type="number"
            id="sequence"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
         <div><label htmlFor="sequence">Description</label></div>
          <input
            type="text"
            id="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
         <div><label htmlFor="sequence">Difficulty level</label></div>
          <input
            type="number"
            id="Difficulty level"
            value={difficultyLevel}
            onChange={(e) => setDifficultylevel(e.target.value)}
          />
        </div>
        <div style={{width:500,justifyContent:'space-between',display:'flex',alignItems:'center'}}>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div style={{marginTop:20}}>
        <button type="submit"  style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>Add Chapter</button>
        <button type="button" style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }} onClick={onRequestClose}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCourses;
