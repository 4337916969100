import './App.css';
import AddAssignment from './pages/AddAssignment';
import AddContentForm from './pages/AddContentForm';
import Category from './pages/Category';
import Chapters from './pages/Chapters';
import Contents from './pages/Contents';
import Courses from './pages/Courses';
import Login from './pages/Login';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/category" element={<Category />} />
        <Route path="/course/:categoryId" element={<Courses />} /> {/* Dynamic route for Course */}
        <Route path="/chapter/:courseId" element={<Chapters />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/contents/:chapterId" element={<Contents />} />
        <Route path="/add-content/:chapterId" element={<AddContentForm />} />
        <Route path='/add-assignment/:chapterId' element={<AddAssignment />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
