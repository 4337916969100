import React, { useState } from 'react'
import './Login.css'
import Lock from "../assets/lock.png";
import Mail from "../assets/mail.png"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success,setSuccess] = useState(false)
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        console.log(email + password);

        try {
            const response = await axios.post('https://service.purposefin.app/user/admin/login', {
                email,
                password,
            });
            console.log(response.data.accessToken, "response");
            const accessToken = response.data.accessToken;
            localStorage.setItem('accessToken', accessToken);

            console.log('Login successful!');
            setSuccess(true)
            navigate('/category')
        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
            console.error('There was a problem with the login request:', error);
        }
    };

    return (
        <div >
            <h3 className='heading'>Purpose Content Admin Panel </h3>
            <div className='login'>
                <form onSubmit={handleSubmit} style={{ height: 300, marginTop: 50 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Mail} alt='mail' height={20} width={20} />
                            <label htmlFor='email' style={{ fontFamily: 'Roboto', fontSize: 17, marginLeft: 10, color: '#2A4365' }}>Email address:</label>
                        </div>
                        <input
                            type='email'
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className='input'
                            style={{ backgroundColor: '#EDF2F7', borderRadius: 5 }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Lock} alt='mail' height={20} width={20} />
                            <label htmlFor='password' style={{ fontFamily: 'Roboto', fontSize: 17, marginLeft: 10, color: '#2A4365' }}>Password:</label>
                        </div>
                        <input
                            type='password'
                            id='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className='input'
                            style={{ backgroundColor: '#EDF2F7', borderRadius: 5 }}
                        />
                    </div>
                    <button style={{ backgroundColor: '#2A4365', borderStyle: 'none', borderRadius: 5, fontSize: 17, fontFamily: 'Roboto' }} className='btn' type='submit'>Submit</button>
                </form>
            </div>
            {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        </div>
    )
}

export default Login
