import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import '../pages/AddContentForm.css'; // Import the CSS file

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    backgroundColor: '#D9D9D9',
    textAlign: 'center',
    overflow: 'auto',
  },
};

const EditContentModal = ({ isOpen, onRequestClose, content, fetchContents }) => {
  const { chapterId } = useParams(); // Get the chapter ID from URL parameters
  const [title, setTitle] = useState('');
  const [body, setBody] = useState(''); // This will hold all the text in a single text box
  const [sequence, setSequence] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (content) {
      setTitle(content.title);
      setSequence(content.sequence);
  
      if (content.contentType === 'text' && Array.isArray(content.body)) {
        console.log('Content body array:', content.body);
  
        let contentText = '';
  
        // Loop through the array of objects
        content.body.forEach(item => {
          // For each object in the array, get its keys and concatenate the values
          for (const key in item) {
            console.log(`Key: ${key}, Value: ${item[key]}`); // Debugging log for each key-value pair
            contentText += `${item[key]}\n\n`; // Combine the value into a string
          }
        });
  
        setBody(contentText.trim()); // Set the combined string into body
      }
    }
  }, [content]);
  

  const handleChangeTitle = (e) => setTitle(e.target.value);
  const handleChangeSequence = (e) => setSequence(parseFloat(e.target.value));
  const handleBodyChange = (e) => setBody(e.target.value);

  console.log("BODY TEXT",body);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const bodyContent = {}; // To store the individual lines for paragraphs/headings/subpoints

  //   // Split the content into paragraphs, headings, and subpoints
  //   const lines = body.split('\n\n').filter((line) => line.trim() !== '');
  //   lines.forEach((line) => {
  //     const [key, value] = line.split(':');
  //     if (key && value) {
  //       bodyContent[key.trim()] = value.trim();
  //     }
  //   });
  //   console.log("TITLE",title);
  //   console.log("BODY",body);
  //   console.log("LINES",lines);
  //   console.log("BODY CONTENT",bodyContent);

  //   try {
  //     const response = await axios.put(
  //       `https://service.purposefin.app/content/update/content-text/${content.contentId}`,
  //       { title, body: bodyContent, sequence },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     console.log('Content updated successfully:', response.data);
  //     setSuccess('Content updated successfully');
  //     fetchContents(); // Refresh the content after update
  //     onRequestClose(); // Close modal after successful update
  //   } catch (error) {
  //     setError('There was an error updating the content.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Split the content into paragraphs (or separate lines)
    const bodyContent = [];
    const lines = body.split('\n').filter((line) => line.trim() !== '');
  
    // Assign each line sequentially as para0, para1, etc.
    // lines.forEach((line, index) => {
    //   bodyContent[`para${index}`] = line.trim(); // Each line is treated as a paragraph
    // });
    lines.forEach((line, index) => {
      const paragraphObject = {};
      paragraphObject[`para${index}`] = line.trim(); // Create an object for each paragraph
      bodyContent.push(paragraphObject); // Push the object into the bodyContent array
    });
  
    console.log("TITLE:", title);
    console.log("BODY:", body);
    console.log("LINES:", lines);
    console.log("BODY CONTENT:", bodyContent);
  
    try {
      const response = await axios.put(
        `https://service.purposefin.app/content/update/content-text/${content.contentId}`,
        { title, body: bodyContent, sequence },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Content updated successfully:', response.data);
      setSuccess('Content updated successfully');
      fetchContents(); // Refresh the content after update
      onRequestClose(); // Close modal after successful update
    } catch (error) {
      console.error('Error updating content:', error);
      setError('There was an error updating the content.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Content Modal"
    >
      <h2>Edit Content</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input type="text" id="title" value={title} onChange={handleChangeTitle} required />
        </div>

        <div className="form-group">
          <label htmlFor="body">Content (Paragraphs, Headings, Subpoints):</label>
          <textarea
            id="body"
            value={body}
            onChange={handleBodyChange}
            rows="10"
            required
            placeholder="Enter content here. Use 'Heading:' or 'Subpoint:' as prefixes if needed."
          />
        </div>

        <div className="form-group">
          <label htmlFor="sequence">Sequence:</label>
          <input
            type="number"
            id="sequence"
            value={sequence}
            onChange={handleChangeSequence}
            required
          />
        </div>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}

        <button type="submit" className="btn-submit" disabled={loading} style={{ backgroundColor: '#553C9A', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5 }}>
          {loading ? 'Updating...' : 'Submit'}
        </button>
      </form>
      <button onClick={onRequestClose} className="btn-close" style={{ backgroundColor: '#EF5A6F', height: 25, marginLeft: 10, color: '#F7FAFC', borderStyle: 'none', cursor: 'pointer', width: 100, borderRadius: 5,marginTop:10 }}>Close</button>
    </Modal>
  );
};

export default EditContentModal;
