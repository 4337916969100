import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    backgroundColor: '#D9D9D9',
    textAlign: 'center',
  },
};

const EditCategory = ({ isOpen, onRequestClose, fetchChapters, category }) => {
  const [title, setTitle] = useState('');
  const [sequence, setSequence] = useState('');
  const [tags, setTags] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if (category) {
      setTitle(category.title);
      setSequence(category.sequence);
      setTags(category.tags.join(', '));
    }
  }, [category]);

  const handleEditCategory = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`https://service.purposefin.app/content/update/category/${category.categoryId}`, {
        title,
        sequence,
        tags: tags.split(',').map(tag => tag.trim()),
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log("EDITED", response.data);
      if (response.status === 200) {
        fetchChapters();
        onRequestClose();
      }
    } catch (error) {
      console.error('Error updating category:', error);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Category"
      style={customStyles}
    >
      <h2>Edit Category</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={(e) => { e.preventDefault(); handleEditCategory(); }}>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="sequence">Sequence</label>
          <input
            type="text"
            id="sequence"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="tags">Tags</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            required
          />
        </div>
        <button type="submit" style={{ marginTop: 20 }}>Save Changes</button>
      </form>
    </Modal>
  );
};

export default EditCategory;
