import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    backgroundColor: '#D9D9D9',
    textAlign: 'center',
  },
};

const EditChapterModal = ({ isOpen, onRequestClose, fetchChapters, chapter }) => {
  const [title, setTitle] = useState('');
  const [durationInMinutes, setDurationInMinutes] = useState('');
  const [sequence, setSequence] = useState('');
  const [hasAssignment, setHasAssignment] = useState('');
  const [totalContent, setTotalContent] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if (chapter) {
      setTitle(chapter.title);
      setDurationInMinutes(chapter.durationInMinutes);
      setSequence(chapter.sequence);
      setHasAssignment(chapter.hasAssignment);
      setTotalContent(chapter.totalContent);
    }
  }, [chapter]);

  console.log("CHAPTER",chapter.chapterId);

  const handleEditChapter = async () => {
setLoading(true);
    try {
      const response = await axios.put(`https://service.purposefin.app/content/update/chapter/${chapter.chapterId}`, {
        title,
        durationInMinutes,
        sequence,
        hasAssignment,
        totalContent,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log("EDITED", response.data);
      if (response.status === 200) {
        fetchChapters();
        onRequestClose();
      }
    } catch (error) {
      console.error('Error updating chapter:', error);
    }
    setLoading(false)
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Chapter"
      style={customStyles}
    >
      <h2>Edit Chapter</h2>
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={(e) => { e.preventDefault(); handleEditChapter(); }}>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="durationInMinutes">Duration (minutes)</label>
          <input
            type="number"
            id="durationInMinutes"
            value={durationInMinutes}
            onChange={(e) => setDurationInMinutes(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="sequence">Sequence</label>
          <input
            type="number"
            id="sequence"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
            required
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="hasAssignment">Has Assignment</label>
          <input
            type="text"
            id="hasAssignment"
            checked={hasAssignment}
            onChange={(e) => setHasAssignment(e.target.checked)}
          />
        </div>
        <div style={{ width: 500, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="totalContent">Total Content</label>
          <input
            type="number"
            id="totalContent"
            value={totalContent}
            onChange={(e) => setTotalContent(e.target.value)}
            required
          />
        </div>
        <button type="submit" style={{ marginTop: 20 }}>Save Changes</button>
      </form>
    </Modal>
  );
};

export default EditChapterModal;
